<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col lg="3">
              <h1>
                <feather-icon icon="BookOpenIcon" size="30" class="mr-50" />
                Alunos
              </h1>
            </b-col>
            <b-col lg="6">
              <b-form @submit.prevent="fetchStudents">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filtro.name"
                    placeholder="Buscar Aluno"
                  />
                </b-input-group>
              </b-form>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div class="d-flex">
      <b-pagination
        @input="onChangePage"
        class="ml-auto"
        v-model="currentPage"
        :total-rows="fetchedStudents.totalItems"
        :per-page="perPage"
      ></b-pagination>
    </div>
    <b-card no-body>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchedStudents.content"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Status -->
        <template #cell(approved)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.approved)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusText(data.item.approved) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="approveStudent(data.item.id, !data.item.approved)"
            >
              <feather-icon :icon="!data.item.approved ? 'CheckIcon' : 'XCircleIcon'" />
              <span class="align-middle ml-50"> {{!data.item.approved ? 'Aprovar' : 'Desaprovar'}} Aluno</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              :to="{ name: 'escolasEdit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar Aluno</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <div class="d-flex">
      <b-pagination
        class="ml-auto"
        v-model="fetchedStudents.currentPage"
        :total-rows="fetchedStudents.totalItems"
        :per-page="fetchedStudents.perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { hideSpinner, showSpinner } from '@/services/spinService'
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import usersService from '@/services/usersService'
import { RoleEnum } from '@/utils/constants'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    BForm,
  },
  data: () => ({
    configFlatPickr: { dateFormat: 'd/m/Y', locale: Portuguese },
    filtro: {
      name: '',
    },
    perPage: 10,
    totalUsers: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,
    roleFilter: null,
    planFilter: null,
    statusFilter: null,
    fetchedStudents: [],
    tableColumns: [
      { key: 'fullName', label: 'Nome', sortable: true },
      { key: 'classroom', label: 'Turma', sortable: true },
      { key: 'turno', label: 'Turno', sortable: true },
      { key: 'approved', label: 'Status', sortable: true },
      { key: 'actions', label: 'Acesso', sortable: false },
    ],
  }),
  mounted() {
    this.fetchStudents()
  },
  methods: {
    onChangePage() {
      this.fetchStudents()
    },
    prepareRequest(obj) {
      return {
        ...obj,
        role: RoleEnum.Student, // Student
        page: this.currentPage,
        itemsPerPage: this.perPage,
      }
    },
    async fetchStudents() {
      showSpinner()
      this.fetchedStudents = await usersService.getAll(
        this.prepareRequest(this.filtro)
      )
      hideSpinner()
    },
    resolveUserStatusText: status => (status ? 'Aprovado' : 'Não Aprovado'),

    resolveUserStatusVariant: status => (status ? 'success' : 'danger'),

    async approveStudent(id, approved) {
      showSpinner()
      await usersService.approveStudent(id, approved).then(() => {
        this.fetchStudents()
      })
      hideSpinner()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
